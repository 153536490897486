.application-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.none-found{
    display: flex;
    justify-content: center;
    align-items: center;
}

.application-card {
    grid-column: span 1 !important;
    width: 50% !important;
    max-width: 400px;
    max-height: 400px;
    width: 500px !important;
    height: 400px !important;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 30px;
}

.reason-box {
    max-height: 150px; /* Set the maximum height of the element */
    overflow-y: auto; /* Add this line to enable vertical scrolling */
}

@media screen and (min-width: 768px) {
    .application-cards-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }

    .application-card {
        grid-column: span 1;
        width: 100%;
        max-width: none;
    }

    .accepted-students {
        margin-top: 40px;
    }
}
  