.linkbox {
    width: 100%;
    height: 50px;
    background-color: #00205B;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
#links ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
}

#links li {
    list-style: none;
    padding: 0 3rem;
}

#links a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    transition: 0.5s;
}

#links a:hover {
    color: #b0b0b0;
}
  