.research-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 100px;
}

.title-container {
  margin-bottom: 1rem;
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.description-container {
  width: 60%;
}

.assistants-container {
  width: 30%;
}

.apply-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: 0 auto;
  //border: 2px solid #000;
  border-radius: 5px;
  padding: 30px;
}

.reason-text{
  max-width: 1000px;;
  // maxWidth: '1000px',
  // maxHeight: '200px', 
  // overflowY: 'scroll' 
}