.profile-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 50px;
    padding-top: 50px;
}
  
.user-image {
    display: inline-block;
    vertical-align: top;
    flex-basis: 30%;
    max-width: 415px;
}
  
.user-details {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-left: 1rem;
    flex-basis: 70%;
    max-width: 50%;
}
  
.transparent-card {
    background-color: transparent;
    width: 25%;
    padding-left: 60px;
}
  
.user-details-title {
    text-align: left !important;
}

.research-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin: 30px;
    width: 100%;
}

.research-button {
    margin: 5px;
}
  
.interests {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 50%;
}
  
@media (max-width: 768px) {
    .profile-card {
      flex-direction: column;
    }
  
    .user-image {
      margin-bottom: 20px;
    }
  
    .user-details {
      margin-left: 0;
      text-align: center;
    }
  
    .interests {
      margin-left: 0;
      text-align: center;
    }
}
  
.interests p {
    max-width: 100%;
    width: 785px; 
    height: 200px;
}



  
