.card-selects{
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    gap: 150px;
    padding-bottom: 50px;
    padding-top: 50px;
    
}

.title{
    margin: 20px;
    text-align: center;
}