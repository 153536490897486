.signup-background{
    background-color: #f5f5f5;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
}

.error{
    color: red;
    font-size: small;
    padding-bottom: 10px;
}